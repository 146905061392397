.modal {
    @include flex-center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 200;
    font-family: $modal-font;
    letter-spacing: -1px;
  
    &-container {
      position: relative;
      border-radius: 15px;
      width: 80%;
      max-height: 90vh;
      margin-top: 2em;
      max-width: 600px;
      padding: 2em;
      background-color: white;
      overflow-y: scroll;
  
      .close-btn {
        position: absolute;
        right: 1.5em;
        top: 1.5em;
        border: none;
        background-color: white;
        color: gray;
        font-size: 16px;
        cursor: pointer;
        border-radius: 50%;
        padding: 1em;
        height: 3.25em;
        width: 3.25em;
        @include transitionHover;
  
        &:hover {
          background-color: gainsboro;
          color: black;
          @include transitionHover;
        }
      }
  
      h3 {
        font-size: 1.2rem;
        color: $text-color;
        font-style: inherit;
        letter-spacing: 1px;;

        span {
          color: white;
          background-color: $cta-red;
          padding: 5px 10px;
          border-radius: 7px;
          margin-right: 7px;
        }
      }
  
      h4 span {
        color: $cta-red;
      }
  
      ul {
        li {
          &.low-acc span {
            color: $cta-red;
          }
  
          &.high-acc span {
            color: $cta-blue;
          }
          span {
            font-weight: 700;
  
            &.high-acc {
              color: $cta-blue;
            }
  
            &.low-acc {
              color: $cta-red;
            }
          }
        }
      }
  
      .modal-footnote {
        font-weight: 400;
        font-size: 12px;
        font-style: italic;
        text-align: right;
  
        a {
          color: $cta-blue;
          text-decoration: none;
          font-weight: 700;
          @include transitionHover;
  
          &:hover {
            color: lighten($cta-blue, 0.7);
            @include transitionHover;
            text-decoration: underline;
          }
        }
      }
    }
  }


.routeName {
  font-size: 1.2rem;
  color: $text-color;
  font-style: inherit;
  letter-spacing: 1px;

  span {
    color: white;
    background-color: $cta-red;
    padding: 5px 10px;
    border-radius: 7px;
    margin-right: 7px;
  }
}

