.social-sidebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 1500;
  margin-top: 15px;
  left: 3%;

  a {
    text-decoration: none;
    opacity: 100%;
    margin-right: 10px;

    .social-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      @include transitionHover;

      &:hover,
      &:focus {
        background-color: rgb(209, 229, 255);
        @include transitionHover;
      }

      i {
        color: $cta-blue;
        font-size: 1.5rem;
      }
      svg {
        width: 27px;
        height: 27px;

        path {
          fill: $cta-blue;
        }
      }
    }
  }
}

@keyframes drop-in {
  100% {
    margin-top: 1em;
  }
}

@keyframes fade-in {
  100% {
    opacity: 100%;
  }
}

@media (min-width: $desktop-bp) {
  .social-sidebar {
    flex-direction: column;
    margin-top: 2em;

    a {
      opacity: 0%;
      animation: fade-in 1s forwards, drop-in 1s forwards;
      margin-bottom: 1em;

      &:nth-of-type(2) {
        animation-delay: 500ms;
      }
      &:nth-of-type(3) {
        animation-delay: 900ms;
      }

      .social-icon {
        background-color: $cta-blue;
        width: 50px;
        height: 50px;

        &:hover,
        &:focus {
          background-color: $blue-button;
          @include transitionHover;
        }

        i {
          color: white;
        }
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}
