// colors
$cta-blue: #007bc4;
$blue-button: rgba($cta-blue, 0.8);
$cta-red: rgba(206, 23, 72, 0.74);
$text-color: #575757;

// percentile color keys

$key-five: #FFED39;

$key-four: #EB4F12;

$key-three: #D84091;

$key-two: #8E47F3;

$key-one: #0852C1;





$bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
  0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);

// breakpoints

$tablet-bp: 450px;
$desktop-bp: 1000px;

// fonts

$modal-font: 'Arimo', sans-serif;



@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin transitionHover {
  transition: all 300ms ease-in;
}

@mixin dotNavtransition {
  transition: all 500ms ease-in-out;
}
