@import "config";
@import "socials";
@import "header-animation";
@import "deprecation-alert";
@import "nav";
@import "header";
@import "page";
@import "block-quote";
@import "about";
@import "map";
@import "search";
@import "filters";
@import "modal";
@import "bus-route-details";
@import "footer";
@import "stat-visuals";
@import "route-stats";

html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  line-height: 1.6;
}

.skip-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
.skip-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

strong {
  font-weight: 900;
}

.app {
  width: 100%;
  position: relative;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  z-index: 5;
}

.padding-container {
  padding: 2em 3em;
  width: 80%;
  margin: 0 auto;
}

h1 {
  color: $cta-red;
  font-weight: 900;
  font-size: 2rem;
}

h2 {
  font-size: 1.4rem;

  font-weight: 900;
}

h3 {
  font-size: 1.1rem;
  font-weight: 900;
  color: $cta-blue;
  font-style: italic;
}

hr {
  width: 70%;
  background-color: $cta-blue;
  opacity: 40%;
  height: 1px;
  margin: 0 auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  line-height: 1.6;
  font-size: 14px;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.note {
  background: rgba($cta-blue, 0.2);
  padding: 0.25em 1.5em;
  margin: 0 auto;
  width: 90%;
  border-radius: 8px;
}


@media (max-width: $tablet-bp) {
  .js-plotly-plot {
    width: 100%;
  }
}


