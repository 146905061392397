.route-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    h2 {
        padding-left: 20px;
    }

    .search-container{
        border: 1px solid;
        border-color: lightgray;
        margin-left: auto;
    }
}

.stats-list {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15vh;
    padding-top: 10vh;
    padding-bottom: 10vh;

    * {
        flex: 1 auto;
        margin: 5px;
    }

}

.footnote {
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
    text-align: right;

    a {
        color: $cta-blue;
        text-decoration: none;
        font-weight: 700;
        @include transitionHover;

        &:hover {
            color: lighten($cta-blue, 0.7);
            @include transitionHover;
            text-decoration: underline;
        }
    }
}
