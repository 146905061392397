.bargraph {
  width: 85%;
  max-width: 300px;
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.75em;
  border-left: 1px solid $text-color;
  border-bottom: 1px solid $text-color;

  .bargraph-bar {
    width: 15px;
    background-color: rgb(173, 173, 173);

    &.selected-bar {
      background-color: $cta-red;
    }
  }
}



.ridership,
.fraction {
  @include flex-center;
}

.fraction {
  .bus-graphic-text {


    p {
      font-size: 1.1rem;
      font-weight: 700;
      padding: 1em 0;

      &:first-of-type {
        border-bottom: $text-color solid 1px;
      }
    }

    span {
      color: $cta-blue;
    }
  }
}

.bus-graphic {
  padding: 6em;

  &-text {
    p {
      text-align: center;
      font-weight: 700;
      margin: 0;
      font-size: 1rem;

      &.ridership-number {
        color: $cta-blue;
        font-size: 4rem;
        margin: -25px 0 -15px 0;
        letter-spacing: -2px;
      }
    }

    span {
      color: $cta-red;
      font-weight: 900;
      font-size: 1.4rem;

      &.blue {
        color: $cta-blue;
      }

      &.ghost {
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 3px;
        margin-left: 2px;
      }
    }
  }

  .bus-ghost-container {
    border: 0;
    padding: 0;
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(auto-fit, 40px);
    grid-auto-rows: 1fr min-content;
    grid-gap: 5px;
    align-items: center;
    justify-items: center;
    justify-content: center;

    img {
      width: 30px;
      align-self: center;
      padding: 7px 0;
    }
  }

}

.trip-performance-graph {
  .data-select-button {
    width: 90%;
    padding: 13px 25px;
    margin-top: 20px;
    background-color: gray;
    border: none;
    color: white;
    border-radius: 100px;
    cursor: pointer;
    @include transitionHover;

    &:hover{
      background-color: $blue-button;
      @include transitionHover;
    }

    &-selected{
      @extend .data-select-button;
      background-color: $blue-button;
    }

  }
}