footer {
  background-color: $cta-blue;

  .footer-container {
    max-width: 800px;
    margin: 0 auto;

    p {
      color: white;
      font-size: 1em;
      margin: 0.75em 0;
      text-decoration: underline;
      font-weight: 700;
    }

    ul {
      padding: 0;
      li {
        list-style: none;

        a {
          color: white;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (min-width: $tablet-bp) {
  footer .footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
}
