.bus-route-details {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;

    div.grid-square {
      height: 200px;
      padding: 4em 2em;
      align-content: center;
      
      &.percentile {
        @include flex-center;
        .bus-graphic-text {
          max-width: 300px;
        }
      }
      
    }
  }
}

@media (min-width: $desktop-bp) {
  .bus-route-details {
    .grid {
      grid-template-columns: 1fr 1fr;

      div.grid-square {
        padding: 2em;
        &:first-child {
          border-right: 1px $text-color solid;
        }
        &:nth-child(3) {
          border-top: 1px $text-color solid;
          border-right: 1px $text-color solid;
        }
        &:last-child {
          border-top: 1px $text-color solid;
        }
      }
    }
  }
}
